import useGetCourseDetails from '../../hooks/useGetCourseDetails'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import School from '@mui/icons-material/School'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import course from '../../../../main/course/course'

function PostVisibilityForm({ postFormType, visibility, handleVisibilityChange, courseId }) {
  return (
    <MuiFormControl component="fieldset" variant="filled">
      <MuiFormLabel component="legend">Choose Who Can View Your Post:</MuiFormLabel>

      <MuiRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="community"
        name="radio-buttons-group"
        value={visibility}
        onChange={handleVisibilityChange}>
        {postFormType === 'coursePrompt' && (
          <MuiFormControlLabel
            value="course"
            checked={visibility === 'course'}
            control={<MuiRadio />}
            label={
              <>
                <CourseBadge courseId={courseId} />
                <Typography variant="subtitle1">
                  Your post will only be visible to other members of your course.
                </Typography>
              </>
            }
          />
        )}

        <MuiFormControlLabel
          value="community"
          checked={visibility === 'community'}
          control={<MuiRadio />}
          label={
            <>
              <CommunityBadge />
              <Typography variant="subtitle1">
                Your post will be visible to other CivilTalk UConn members on the main feed and your
                profile. This setting allows your post to be viewed by all members of the UConn
                Community.
              </Typography>
            </>
          }
        />
      </MuiRadioGroup>
    </MuiFormControl>
  )
}

export default PostVisibilityForm

// ***************************** //
// *** Local Components *** //
// ***************************** //
function CourseBadge({ courseId }) {
  const { courseSubject, courseNumber, courseTitle, courseSeason, courseYear } =
    useGetCourseDetails(courseId)

  const tooltipTitle = `This post will only be visible to members of ${courseSubject} ${courseNumber} - ${courseTitle}, ${courseSeason} ${courseYear}.`

  return (
    <MuiBox>
      <Typography>Visible only to:</Typography>
      <MuiTooltip title={tooltipTitle}>
        <MuiChip
          icon={<School role="img" />}
          label={`${courseSubject} ${courseNumber}`}
          // color="primary"
          sx={{ marginRight: '0.5rem' }}
          size="small"
        />
      </MuiTooltip>
    </MuiBox>
  )
}

function CommunityBadge() {
  const tooltipTitle = `This post is visible to all members of the UConn Community. If this is a course-related post, it will also be visible to members of your course.  If you wish to make this post private to your course, please click the triple dots on the upper-right corner of this post and select the Edit option from the dropdown menu.`

  return (
    <MuiBox>
      <Typography>Visible to:</Typography>
      <MuiTooltip
        title={
          <>
            <Typography>
              This post will be visible to <b>all members of the UConn Community.</b>
            </Typography>
          </>
        }>
        <MuiChip icon={<School role="img" />} label="UConn Community" size="small" />
      </MuiTooltip>
    </MuiBox>
  )
}

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginTop: '0.25rem'
}))

const MuiChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light
}))

const MuiSchoolIcon = styled(School)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: '.1rem',
  marginRight: '.4rem',
  fontSize: '1.2rem'
}))

const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.gray,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
    padding: '1rem'
  }
}))

const MuiFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.25rem',
  position: 'relative',
  '& .MuiFormLabel-root': {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 500
  }
}))

const MuiFormLabel = styled(FormLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  color: theme.palette.common.white,
  // backgroundColor: theme.palette.secondary.light,
  fontSize: '1.25rem',
  fontWeight: 500,
  padding: '0.5rem 0 0.25rem 0',
  borderRadius: '0.25rem',
  '&.Mui-focused': {
    color: theme.palette.text.primary
  }
}))

const MuiFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
  fontWeight: 500,
  padding: '0.5rem',
  // border: `2px solid ${theme.palette.secondary.main}`,
  '&.Mui-focused': {
    color: theme.palette.text.primary
  },
  '& p': {
    fontSize: '1.25rem',
    fontWeight: 500
  },
  backgroundColor: '#eaeaea',
  borderRadius: '0.25rem',
  '& .sub-header': {
    fontSize: '1rem',
    fontWeight: 300,
    color: theme.palette.text.main
  }
}))

const MuiRadioGroup = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  margin: '1rem 0'
}))

const MuiRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '2.25rem',
  '&.Mui-checked': {
    color: theme.palette.primary.main
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.85rem'
  }
}))
