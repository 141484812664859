import React from 'react'
import useGetCourseDetails from '../../hooks/useGetCourseDetails'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import School from '@mui/icons-material/School'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

function VisibilityIndicator({ visibility, courseId }) {
  return (
    <MuiBox>
      {visibility === 'course' ? <CourseBadge courseId={courseId} /> : <CommunityBadge />}
    </MuiBox>
  )
}

export default VisibilityIndicator

// ***************************** //
// *** Local Components *** //
// ***************************** //
function CourseBadge({ courseId }) {
  const { courseSubject, courseNumber, courseTitle, courseSeason, courseYear } =
    useGetCourseDetails(courseId)

  const tooltipTitle = `This post is only visible to members of ${courseSubject} ${courseNumber} - ${courseTitle}, ${courseSeason} ${courseYear}.`

  return (
    <MuiBox>
      <Typography>Visible only to:</Typography>
      <MuiTooltip title={tooltipTitle}>
        <MuiChip
          icon={<School role="img" />}
          label={`${courseSubject} ${courseNumber}`}
          // color="primary"
          sx={{ marginRight: '0.5rem' }}
          size="small"
        />
      </MuiTooltip>
    </MuiBox>
  )
}

function CommunityBadge() {
  const tooltipTitle = `This post is visible to all members of the UConn Community. If this is a course-related post, it will also be visible to members of your course.  If you wish to make this post private to your course, please click the triple dots on the upper-right corner of this post and select the Edit option from the dropdown menu.`

  return (
    <MuiBox>
      <Typography>Visible to:</Typography>
      <MuiTooltip
        title={
          <>
            <Typography>
              This post is visible to <b>all members of the UConn Community.</b>
            </Typography>
          </>
        }>
        <MuiChip icon={<School role="img" />} label="UConn Community" size="small" />
      </MuiTooltip>
    </MuiBox>
  )
}

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginTop: '0.25rem'
}))

const MuiChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light
}))

const MuiSchoolIcon = styled(School)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: '.1rem',
  marginRight: '.4rem',
  fontSize: '1.2rem'
}))

const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.gray,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
    padding: '1rem'
  }
}))
