import moment from 'moment'
import { history } from '../../../../../history/history'
import useGetCourseDetails from '../../hooks/useGetCourseDetails'
import UserAvatar from '../../../user-avatar/user-avatar'
import EmotionChip from '../emotion-chip/emotion-chip'
import VisibilityIndicator from '../visibility-indicator/visibility-indicator'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import {
  TakeHeaderContainer,
  AvatarContainer,
  TakeHeaderInnerContainer,
  Name,
  DateString
} from '../../styled/post-comps'
import { EmotionCardsContainer, EmotionMessage, EmotionCard } from '../../styled/emotion-comps'

function PostHeader({
  take,
  hostProfile,
  takeProfileImage,
  verified,
  takeUserName,
  colorForEmotion
}) {
  const { courseSubject, courseNumber, courseTitle, courseSeason, courseYear } =
    useGetCourseDetails(take.courseId)
  const handleNavigateToOrganization = (organizationId) => {
    console.log('handleNavigateToOrganization ')
    history.push(`/organization/${organizationId}`)
  }

  const handleNavigateToProfile = (profileId) => {
    console.log('handleNavigateToProfile ')
    history.push('/profile/' + profileId)
  }

  return (
    <>
      <TakeHeaderContainer>
        <AvatarContainer
          onClick={
            take.organizationId
              ? () => handleNavigateToOrganization(take.organizationId)
              : () => handleNavigateToProfile(hostProfile.userId)
          }>
          <UserAvatar
            src={takeProfileImage}
            width={'2.3rem'}
            height={'2.3rem'}
            borderWidth={'0.2rem'}
            verified={verified}
          />
        </AvatarContainer>

        <TakeHeaderInnerContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Name
              onClick={
                take.organizationId
                  ? () => handleNavigateToOrganization(take.organizationId)
                  : () => handleNavigateToProfile(hostProfile.userId)
              }>
              {takeUserName}
            </Name>
            {/** *** EMOTIONS *** */}
            <EmotionChip emotion={take.emotion} colorForEmotion={colorForEmotion} />
          </div>

          <VisibilityIndicator visibility={take.visibleTo.key} courseId={take.courseId} />

          <DateString>{moment(take.whenCreated).fromNow()}</DateString>
        </TakeHeaderInnerContainer>
      </TakeHeaderContainer>
    </>
  )
}

export default PostHeader
